import { FunctionComponent, useEffect } from "react";
import { RouteComponentProps } from "@reach/router";
import { navigate } from "gatsby";

// @ts-ignore
import i18n from "../../i18n/languages";

const getLangKey = (lang: string): string => lang.toLowerCase().split("-")[0];

interface RedirectProps extends RouteComponentProps {
  pageContext: {
    redirectTo: string[];
  };
}

const RedirectPage: FunctionComponent<RedirectProps> = ({
  path,
  pageContext
}) => {
  useEffect(() => {
    // Get the default language
    let lang = i18n.defaultLanguage;

    // Get the browser's language if available
    if (typeof window !== "undefined") {
      const { navigator }: any = window;
      const browserLanguage = navigator.languages
        ? navigator.languages[0]
        : navigator.language || navigator.userLanguage; // 'userLanguage' is IE only

      if (Object.keys(i18n.languages).includes(browserLanguage)) {
        lang = browserLanguage;
      }
    }

    // Check if the preferred language is available
    if (pageContext.redirectTo) {
      for (const toPath of pageContext.redirectTo) {
        // Redirect if it is
        if (toPath.match(new RegExp(`^\/${getLangKey(lang)}`))) {
          navigate(toPath, { replace: true });
          return;
        }
      }
    }

    // Redirect to the default language if it isn't
    const defaultLangKey = i18n.languages[i18n.defaultLanguage].key;
    navigate(`/${defaultLangKey}${path}`, { replace: true });
  }, []);

  return null;
};

export default RedirectPage;
